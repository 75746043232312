<template>
  <div
    class="merchants-recommend"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div class="head-wrap">
      <h3 class="w-title-gradients">品牌智能匹配
        <el-tooltip
          class="item"
          popper-class="planTooltip"
          effect="dark"
          placement="bottom"
        >
          <span>
            <i class="icon-help-tips"></i>
          </span>
          <div slot="content">取当前品牌在本项目的主营铺位，根据项目、商圈、楼层、铺位、面积、规划业态、首选品牌进行智能推荐，匹配更吻合的品牌</div>
        </el-tooltip>
      </h3>
      <div class="screen-head">
        <div class="condition-wrap">
          <span>匹配项目：</span> {{storeInfo.projectName}}
        </div>
        <div class="condition-wrap">
          <span>所属商圈：</span>{{storeInfo.shangQuan ? storeInfo.shangQuan : '-'}}
        </div>
        <div class="floor-flex">
          <span>落位楼宇/楼层：</span>
          <div class="w-select-screen-border">
            <el-cascader
              class="select-extra"
              popper-class="black-select-panel w-block-select-down w-block-select-down-none"
              :options="floorList"
              v-model="floorId"
              @change="changeFloor"
              ref="myCascader"
              filterable
            ></el-cascader>
          </div>
        </div>
        <div class="floor-flex">
          <span>铺位号：</span>
          <div class="w-select-screen-border">
            <el-select
              popper-class="w-block-select-down"
              v-model="berthId"
              placeholder="请选择"
              size='medium'
              :disabled="berthList && berthList.length === 1"
              class="select-extra"
              @change="changeBerth"
            >
              <el-option
                v-for="item in berthList"
                :key="item.berthId"
                :label="item.berthNo"
                :value="item.berthId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="condition-wrap">
          <span>铺位面积：</span>{{buildingArea ? buildingArea + '㎡' : '-'}}
        </div>
        <div
          class="condition-wrap"
          v-if="berthList && berthList.length > 0"
        >
          <span>铺位规划业态：</span>{{industryName ? industryName : '-'}}
        </div>
        <div
          class="condition-wrap"
          v-if="berthList && berthList.length > 0"
        >
          <span>首选品牌：</span>{{brandName ? brandName : '-'}}
        </div>
      </div>
    </div>
    <div class="select-screen-wrap">
      <div class="brand-class">
        <span class="title">品牌分类</span>
        <div>
          <el-radio-group
            v-model="industryId"
            @change="matchRadio"
          >
            <el-radio
              v-for="item in industryList"
              :key="item.id"
              :label="item.id"
            >{{item.item}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="match-factor">
        <span class="title">
          匹配因素
        </span>
        <div class="check-item-wrap">
          <el-checkbox-group
            v-model="matchList"
            @change="matchChange"
          >
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <el-checkbox label="1">铺位面积</el-checkbox>
              <div slot="content">开店面积与铺位面积相匹配的品牌</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <el-checkbox
                label="2"
                v-if="brandDetail.planBrands && brandDetail.planBrands.length > 0"
              >品牌档次</el-checkbox>
              <div slot="content">品牌档次与首选落位品牌一致的品牌</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <el-checkbox
                label="3"
                v-if="brandDetail.planBrands && brandDetail.planBrands.length > 0"
              >业态标签</el-checkbox>
              <div slot="content">业态标签与首选落位品牌有重合的品牌</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <el-checkbox label="5">楼层偏好</el-checkbox>
              <div slot="content">偏好本楼层大于30%的品牌</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <el-checkbox label="4">品牌进驻意向</el-checkbox>
              <div slot="content">品牌在当前项目拓展开店的意向</div>
            </el-tooltip>
          </el-checkbox-group>
          <div class="percentage">
            <div class="ranks">
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <a
                  class="first"
                  @click="ranksClick(1)"
                  :class="[ranksIdx === 1 || ranksIdx === 2 || ranksIdx === 3 ? 'active' : '']"
                  href="javascript:;"
                >
                  <span class="bg"></span>
                  <span
                    class="bar"
                    style="height: 10px;"
                  ></span>
                </a>
                <div slot="content">进驻概率小于30%的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <a
                  @click="ranksClick(2)"
                  :class="[ranksIdx !== 3 && ranksIdx !== null ? 'active' : '']"
                  href="javascript:;"
                >
                  <span class="bg"></span>
                  <span
                    class="bar"
                    style="height: 10px;"
                  ></span>
                </a>
                <div slot="content">进驻概率介于30%到60%的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <a
                  @click="ranksClick(3)"
                  :class="[ranksIdx !== 2 && ranksIdx !== 3  && ranksIdx !== null ? 'active' : '']"
                  href="javascript:;"
                >
                  <span class="bg"></span>
                  <span
                    class="bar"
                    style="height: 10px;"
                  ></span>
                </a>
                <div slot="content">进驻概率大于60%的品牌</div>
              </el-tooltip>
            </div>
            <span>{{rankText}}</span>
          </div>
        </div>
      </div>
      <div class="sort-condition-wrap">
        <span
          @click="sortClick('defultScore')"
          :class="[sortBy === 'defultScore' ? 'active': '']"
        >默认排序</span><span
          @click="sortClick('HotScore')"
          :class="[sortBy === 'HotScore' ? 'active': '']"
        >按人气指数排序</span><span
          @click="sortClick('PotentialScore')"
          :class="[sortBy === 'PotentialScore' ? 'active': '']"
        >按潜力指数排序</span><span
          @click="sortClick('OperatorScore')"
          :class="[sortBy === 'OperatorScore' ? 'active': '']"
        >按租金贡献指数</span>
      </div>
    </div>
    <div
      class="brand-wrap"
      v-if="brandList.recommendList && brandList.recommendList.length > 0"
    >
      <ul>
        <li
          v-for="item in brandList.recommendList"
          :key="item.id"
          @click="toDetail(item)"
        >
          <div class="brand-item">
            <div class="logo">
              <img
                :src="item.brandLogo ? item.brandLogo : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
              <span class="ellipsis2">{{item.brandName}}{{item.brandEnglishName ? '(' + item.brandEnglishName + ')' : ''}}</span>
            </div>
            <p v-if="item.areaMin">
              <span>{{item.industry}}</span>{{item.areaMin?'/'+item.areaMin+'-':''}}{{item.areaMax?item.areaMax+'平米':''}}
            </p>
            <p v-if="!item.areaMin">{{item.industry}}</p>
            <p
              class="ellipsis"
              v-if="item.shopNum"
            >{{'已进驻' + item.shopNum + '家购物中心'}}{{item.shopNum && item.chainMarketNum ? '+': ''}}{{item.chainMarketNum>0?item.chainMarketNum + '家百货':''}}</p>
            <p
              class="ellipsis"
              v-else
            >{{'已进驻' + item.shopNum + '家购物中心'}}{{item.shopNum && item.chainMarketNum ? '+': ''}}{{item.chainMarketNum>0?'已进驻' + item.chainMarketNum + '家百货':''}}</p>
            <span
              class="brand-label-list"
              v-if="item.tagName"
            >{{item.tagName}}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="clearfix"></div>
    <div
      class="details-no-data"
      v-if="brandList.recommendList && brandList.recommendList.length === 0"
    >
      <img
        src="@/assets/images/public/not-plandata.png"
        alt=""
      >
      <p>抱歉，暂无推荐品牌</p>
    </div>
    <div class="w-page-sty">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="pageChange"
        :page-size="pageSize"
        :current-page="pageNum"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'merchantsRecommend',
  data () {
    return {
      loading: true,
      floorId: [],
      floorList: [],
      berthId: '',
      berthList: [],
      industryId: '0',
      ranksIdx: null,
      industryList: [],
      matchList: [],
      rankText: '',
      sortBy: 'defultScore',
      brandList: [],
      storeInfo: {},
      pageNum: 1,
      brandDetail: {},
      pageSize: 24,
      totalCount: 0,
      industryName: '',
      brandName: '',
      buildingArea: ''
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    brandId: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  mounted () {
    // console.log(this.filtroProject)
    // console.log(this.filtroStore)
    // console.log(this.brandId)
    // console.log(this.projectName)
    this.init()
  },
  watch: {
    filtroProject (newValue) {
    },
    filtroStore (newValue) {
      this.floorId = []
      this.init()
    }
  },
  methods: {
    init () {
      const params = {
        brandId: this.brandId,
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.post(api.getBuildingForFloor, params).then(res => {
        this.storeInfo = res.data.data.find((item) => {
          return item.storeCode === this.filtroStore
        })
        this.floorId.push(this.storeInfo.buildingFloorList[0].value)
        this.floorId.push(this.storeInfo.buildingFloorList[0].children[0].value)
        this.berthList = this.storeInfo.storeInfoList.filter((item) => {
          return item.floorCode === this.storeInfo.buildingFloorList[0].children[0].value
        })
        this.floorList = this.storeInfo.buildingFloorList
        this.berthId = this.berthList[0].berthId
        this.industryName = this.berthList[0].industry
        this.brandName = this.berthList[0].brandName
        this.buildingArea = this.berthList[0].buildingArea
        this.getStoreDetail()
      })
    },
    getStoreDetail () {
      this.axios.post(api.getMapBrandDetails, { berthId: this.berthId })
        .then((res) => {
          this.brandDetail = res.data.data
          if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
            this.getBerthList()
          } else {
            this.getBrandList()
          }
        })
    },
    changeFloor (val) {
      if (val[1]) {
        this.loading = true
        this.berthList = this.storeInfo.storeInfoList.filter((item) => {
          return item.floorCode === val[1]
        })
        this.berthId = this.berthList[0].berthId
        this.industryName = this.berthList[0].industry
        this.brandName = this.berthList[0].brandName
        this.buildingArea = this.berthList[0].buildingArea
        this.industryId = '0'
        this.getStoreDetail()
      }
    },
    changeBerth () {
      this.pageNum = 1
      this.loading = true
      const data = this.berthList.find((item) => {
        return item.berthId === this.berthId
      })
      this.industryName = data.industry
      this.brandName = data.brandName
      this.buildingArea = data.buildingArea
      this.industryId = '0'
      this.getStoreDetail()
      // if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
      //   this.getBerthList()
      // } else {
      //   this.getBrandList()
      // }
    },
    matchRadio (val) {
      this.pageNum = 1
      this.loading = true
      if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
        this.getBerthList()
      } else {
        this.getBrandList()
      }
    },
    matchChange () {
      this.pageNum = 1
      this.loading = true
      if (this.matchList.indexOf('4') > -1) {
        this.rankText = '较强'
        this.ranksIdx = 1
      } else {
        this.rankText = ''
        this.ranksIdx = null
      }
      if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
        this.getBerthList()
      } else {
        this.getBrandList()
      }
    },
    ranksClick (index) {
      this.pageNum = 1
      this.loading = true
      if (this.matchList.indexOf('4') === -1) {
        this.matchList.push('4')
      }
      this.ranksIdx = index
      this.rankText = index === 1 ? '较强' : (index === 2 ? '一般' : '较弱')
      if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
        this.getBerthList()
      } else {
        this.getBrandList()
      }
    },
    sortClick (index) {
      this.pageNum = 1
      this.loading = true
      this.sortBy = index
      if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
        this.getBerthList()
      } else {
        this.getBrandList()
      }
    },
    pageChange (val) {
      this.loading = true
      this.pageNum = val
      if (this.brandDetail.planBrands && this.brandDetail.planBrands.length === 0) {
        this.getBerthList()
      } else {
        this.getBrandList()
      }
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      })
    },
    getBerthList () {
      this.axios.post(api.getRecommendBerth, {
        areaMatch: this.matchList.indexOf('1') === -1 ? 0 : 1,
        floorMatch: this.matchList.indexOf('5') === -1 ? 0 : 1,
        berthId: this.berthId,
        projectId: this.filtroProject,
        industryId: this.industryId,
        sortBy: this.sortBy,
        duelMatch: this.matchList.indexOf('4') === -1 ? 0 : 1,
        duelScreen: this.ranksIdx > 0 ? this.ranksIdx : 0,
        pageNum: this.pageNum,
        pageSize: '24'
      })
        .then((res) => {
          this.industryList = res.data.data.map.industryList
          this.brandList = res.data.data.map
          this.totalCount = res.data.data.totalCount
          this.loading = false
        })
    },
    getBrandList () {
      const params = {
        areaMatch: this.matchList.indexOf('1') === -1 ? 0 : 1,
        berthId: this.berthId,
        brandGradeMatch: this.matchList.indexOf('2') === -1 ? 0 : 1,
        brandId: this.brandDetail.planBrands[0].brandId,
        gbid: this.brandDetail.planBrands[0].gbid,
        duelMatch: this.matchList.indexOf('4') === -1 ? 0 : 1,
        duelScreen: this.ranksIdx > 0 ? this.ranksIdx : 0,
        floorMatch: this.matchList.indexOf('5') === -1 ? 0 : 1,
        industryId: this.industryId,
        pageNum: this.pageNum,
        pageSize: '24',
        projectId: this.filtroProject,
        sortBy: this.sortBy,
        tagnameMatch: this.matchList.indexOf('3') === -1 ? 0 : 1
      }
      this.axios.post(api.getRecommendByBrand, params).then(res => {
        this.industryList = res.data.data.map.industryList
        this.brandList = res.data.data.map
        this.totalCount = res.data.data.totalCount
        this.loading = false
      })
    },
    toDetail (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.merchants-recommend
  padding-right 23px
  margin-bottom 48px
.select-extra>>>
  background #373A43
  border-radius 2px
  .el-input__inner
    box-sizing border-box
    width 110px
    height 34px
    border 1px solid #373A43
    background none
    color #808191
    border-radius 2px
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.head-wrap
  height 120px
  background-color #272930
  border-radius 2px
  .screen-head
    display flex
    align-items center
    height 92px
    padding-left 16px
    .condition-wrap
      font-size 14px
      color #808191
      font-weight 400
      margin-right 70px
      span
        font-size 14px
        color #fff
        font-weight 400
    .floor-flex
      display flex
      align-items center
      margin-right 16px
      span
        font-size 14px
        color #fff
        font-weight 400
.select-screen-wrap
  margin-top 14px
  background-color #272930
  padding 0 14px
  .brand-class
    display flex
    height 83px
    border-bottom 1px solid #3D3F45
    align-items center
    .title
      font-size 14px
      color #fff
      font-weight 400
      margin-right 32px
      // width 80px
      display inline-block
  .match-factor
    display flex
    height 83px
    align-items center
    border-bottom 1px solid #3D3F45
    .title
      font-size 14px
      color #fff
      font-weight 400
      margin-right 32px
    .check-item-wrap
      display flex
      .el-checkbox-group
        label
          margin-bottom 0 !important
          &:last-child
            margin-right 10px
      .percentage
        &>span
          float left
          width 38px
          height 20px
          font-size 12px
          line-height 20px
          text-align center
          border-radius 2px
          color #fff
        .ranks
          float left
          margin-left -2px
          margin-top 3px
          .first
            width 10px
          .active
            .bar
              background #3E8CFF
          a
            position relative
            float left
            height 10px
            width 10px
            margin-right 2px
            &:hover
              .bar
                background #3E8CFF
            .bg
              position absolute
              top 0
              left 0
              height 10px
              width 10px
              border-left none
              background #272930
            .bar
              position absolute
              left -1px
              bottom -2px
              width 10px
              background #424752
.sort-condition-wrap
  display flex
  span
    font-size 16px
    color #81848B
    margin-right 60px
    border-bottom 2px solid #272930
    padding 10.5px 0
    cursor pointer
    &.active
      color #fff
      border-bottom 2px solid #F89407
      padding 10.5px 0
      font-weight bold
.brand-wrap
  margin-top 14px
  ul li
    float left
    width 270px
    height 174px
    background-color #272930
    border-radius 2px
    margin-right 14px
    margin-bottom 14px
    cursor pointer
    &:nth-child(6n)
      margin-right 0
  .brand-item
    padding 10px
    .logo
      display flex
      // align-items center
      margin-bottom 15px
      img
        width 60px
        height 50px
        border 1px solid #424752
        // object-fit cover
      span
        margin-left 10px
        color #fff
        font-weight bold
        font-size 16px
        line-height 22px
        cursor pointer
        text-decoration underline
        height 44px
    p
      font-size 14px
      line-height 18px
      font-weight 500
      color #808191
    .brand-label-list
      display inline-block
      font-size 12px
      color #C9BAAB
      padding 4px 10px
      border-radius 15px
      background-color rgba(255, 249, 243, 0.1)
      margin-top 18px
.details-no-data
  margin-top 100px
  text-align center
  p
    color #fff
    font-size 12px
    margin-top 10px
</style>
